import { useEffect, useState } from "react"

import { CONFIG } from "@meili/config"
import { PartnerContentAPIEndpoint } from "@meili/services/api/endpoints"
import { decodeBase64toJSON, encodeJSONtoBase64 } from "@meili/utils/dist/utils"

import { useMeiliParams } from "integration/MeiliParamsContext"
import { getLanguage } from "localisation/utils"

import {
  doPartnerContentConfigAPI,
  doPartnerContentResponse
} from "@meili/services/src/api/contentAPI"

interface Response {
  [key: string]: {
    [key: string]: string
  }
}

const renderMessage = (message: string, ptid: string) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.log(`${message} - ${ptid}`)
  }
}

export const contentFallback = (
  response: Response,
  key: string,
  locale: string,
  language: string
) =>
  response?.[locale ?? language]?.[key] ??
  response?.[language]?.[key] ??
  response?.default?.[key]

const processContentObject = (
  contentWithoutPrefix: Record<string, any>,
  contentApiUrl: string
) =>
  Object.entries(contentWithoutPrefix).reduce((prev, [key, value]) => {
    if (key === "promoTerms" && value && typeof value === "object") {
      return {
        ...prev,
        [key]: Object.entries(value).reduce(
          (acc, [vendor, path]) => ({
            ...acc,
            [vendor]: `${contentApiUrl}/${path}`
          }),
          {}
        )
      }
    }
    return {
      ...prev,
      [key]: `${contentApiUrl}/${value}`
    }
  }, {})

function usePartnerContent() {
  const { ptid, locale, query } = useMeiliParams()
  const [content, setContent] = useState({})

  const encodedQuery = encodeJSONtoBase64(query || null)

  useEffect(() => {
    if (!ptid) {
      return
    }
    const language = getLanguage(locale)
    const [stylePartnerID, styleTouchPointId] = ptid.split(".")

    const fetchData = async () => {
      try {
        const decodedQuery = decodeBase64toJSON(encodedQuery)

        // Start the initial API call to get rule-based content
        const responsePromise = doPartnerContentConfigAPI(decodedQuery, ptid)

        // Start fallback URL preparation concurrently
        const fallbackUrl = `${PartnerContentAPIEndpoint()}/${stylePartnerID}/${styleTouchPointId}/content/content.json`

        // Resolve the initial API call
        const response = await responsePromise

        let url = ""
        if (response.status === 200) {
          const contentConfig = await response.json()
          url = `${CONFIG.REACT_APP_CONTENT_API}/${contentConfig.cloudfrontLink}`
        } else {
          // Fallback to default content.json file
          url = fallbackUrl
        }

        // Fetch content and translations concurrently
        const contentResponsePromise = doPartnerContentResponse(url)
        const contentResponse = await contentResponsePromise

        const contentJson = await contentResponse.json()

        const contentWithoutPrefix = {
          ...contentJson.default,
          ...contentJson[language]
        }

        const processedContent = processContentObject(
          contentWithoutPrefix,
          CONFIG.REACT_APP_CONTENT_API
        )

        setContent(processedContent)

        const translationUrl = contentFallback(
          contentJson,
          "translations",
          locale ?? "en-GB",
          language
        )

        if (translationUrl) {
          try {
            // Fetch translations in parallel
            const translationsResponsePromise = fetch(
              `${CONFIG.REACT_APP_CONTENT_API}/${translationUrl}`,
              {
                method: "GET",
                headers: {
                  "Content-type": "application/json",
                  "Cache-Control": "no-cache"
                }
              }
            )

            const translationsResponse = await translationsResponsePromise
            const translations = await translationsResponse.json()

            setContent((current) => ({
              ...current,
              translations:
                translations[locale ?? language] ??
                translations[language] ??
                translations.default
            }))
          } catch (error) {
            renderMessage(
              `No translation content found for language ${language} for PTID`,
              ptid
            )
          }
        }
      } catch (error) {
        renderMessage("No partner content found for PTID", ptid)
      }
    }

    fetchData()
  }, [ptid, encodedQuery, locale])

  return content
}

export default usePartnerContent
