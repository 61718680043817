import { PartnerContentConfigAPIEndpoint } from "@meili/services/api/endpoints"
import { createAvailAndPartnerParams } from "@meili/services/api/searchAPI"

export const doPartnerContentConfigAPI = (query, ptid) => {
  return fetch(PartnerContentConfigAPIEndpoint(), {
    method: "POST",
    headers: {
      ptid,
      "Content-type": "application/json",
      "Cache-Control": "no-cache"
    },
    body: JSON.stringify(
      query && query.length > 0 ? createAvailAndPartnerParams(query[0]) : {}
    )
  })
}

export const doPartnerContentResponse = (url) => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Cache-Control": "no-cache"
    }
  })
}

export default {
  doPartnerContentConfigAPI,
  doPartnerContentResponse
}
